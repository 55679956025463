var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', [_c('div', {
          staticClass: "drictions-list"
        }, [_c('div', {
          staticClass: "drictions-list-row"
        }, [_c('div', {
          staticClass: "drictions-list-Q"
        }, [_c('span', [_vm._v("Q：")]), _c('span', [_vm._v(_vm._s(_vm.shouceList.question))])]), _c('div', {
          staticClass: "drictions-list-A"
        }, [_c('span', [_vm._v("A：")]), _c('span', {
          staticStyle: {
            "display": "inline-block"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.shouceList.answer)
          }
        })])])])])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };